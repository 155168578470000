import * as React from 'react'
import { Layout } from 'ui'

const NotFoundPage = () => (
  <Layout>
    <h1>404</h1>
  </Layout>
)

export default NotFoundPage
